import React, { memo } from "react";
import type { ElementOf } from "ts-essentials";

import type { Article } from "@/data-models/articles.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { devWarn } from "@/util/dev.mjs";

const REGEXP_HEADINGS = /^h\d$/i;

export default memo(function ArticleItem(
  item: ElementOf<ElementOf<Article["sections"]>>,
): React.JSX.Element {
  if (REGEXP_HEADINGS.test(item.type)) {
    return React.createElement(item.type.toLowerCase(), null, item.value);
  }
  switch (item.type) {
    case "image": {
      return (
        <img
          src={item.value}
          alt={item.type}
          width="100%"
          height="auto"
          loading="lazy"
        />
      );
    }
    case "list-item": {
      return <li>{item.value}</li>;
    }
    case "paragraph": {
      return <p>{item.value}</p>;
    }
    case "youtube": {
      return (
        <iframe
          src={item.value}
          allowFullScreen
          style={{
            border: "none",
            width: "100%",
            height: `518px`, // YouTube standard video ratio for Blitz.gg container
          }}
        />
      );
    }
    case "video": {
      return (
        <video width="100%" height="auto" muted playsInline autoPlay loop>
          <source src={item.value} type="video/mp4" />
        </video>
      );
    }
    case "strong": {
      return (
        <>
          <strong>{item.value}</strong>{" "}
        </>
      );
    }
    case "em": {
      return (
        <p className="text-center">
          <em>{item.value}</em>
        </p>
      );
    }
    case "table": {
      const data = item.value as unknown as (string | number)[][];
      if (!Array.isArray(data) || data.length < 2) return null;
      const cols = data[0].map((i, idx) => ({
        display: i as string,
        isStat: false,
        primary: !idx,
        align: !idx ? "left" : "center",
      }));
      const rows = data.reduce(
        (acc, cur, idx) => {
          if (!idx) return acc;
          acc.push(cur.map((i) => ({ display: String(i), value: i })));
          return acc;
        },
        [] as { display: string; value: string | number }[][],
      );
      return (
        <DataTable
          rows={rows}
          cols={cols}
          sortable={false}
          sortCol={1}
          style={{ margin: "var(--sp-4) 0" }}
        />
      );
    }
    default: {
      devWarn(
        "Feature News: Encountered new article item type, please handle the rendering logic",
        item.type,
      );
      return null;
    }
  }
});
