import React, { useMemo } from "react";
import { css } from "goober";
import { LoadingSpinner } from "clutch/src/LoadingSpinner/LoadingSpinner.jsx";

import { isPersistent, readState } from "@/__main__/app-state.mjs";
import { isValidated } from "@/__main__/get-data.mjs";
import type { Meta } from "@/__main__/router.mjs";
import type { Article } from "@/data-models/articles.mjs";
import ArticleItem from "@/feature-news/ArticleItem.jsx";
import { REGEXP_ROOT, useArticle } from "@/feature-news/util.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { ArticleHeader } from "@/shared/GameArticle.jsx";
import { classNames } from "@/util/class-names.mjs";
import globals from "@/util/global-whitelist.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Article(): React.JSX.Element {
  const {
    news: { articles, preview: $preview },
  } = useSnapshot(readState);
  const { articleId } = useArticle();
  const article = useMemo(() => articles[articleId], [articles, articleId]);
  const preview = useMemo(() => $preview[articleId], [$preview, articleId]);
  const { sections, isLoadedArticle } = useMemo(
    () => ({
      sections: Array.isArray(article?.sections) ? article?.sections : [],
      isLoadedArticle:
        [isValidated, isPersistent].some((symbol) => article?.[symbol]) ||
        article instanceof Error,
    }),
    [article],
  );
  const isLoadedPreview = useMemo(
    () =>
      [isValidated, isPersistent].some((symbol) => preview?.[symbol]) ||
      preview instanceof Error,
    [preview],
  );
  if (!isLoadedArticle || !isLoadedPreview) return <LoadingSpinner />;
  if ((isLoadedArticle && !sections.length) || !preview?.title) return null;
  return (
    <Container className={classNames(cssArticle(), "flex column")?.className}>
      <ArticleHeader
        title={[`news:article.header.title.${articleId}`, preview.title]}
        excerpt={[`news:article.header.header.${articleId}`, preview.excerpt]}
        image={preview.imgUrl}
        date={preview.date}
      />
      {sections.map((section, sId) => (
        <section key={sId}>
          {section.map((item, iId) => (
            // @ts-ignore - Same typings but for some reason readonly is attached during model validation
            <ArticleItem type={item.type} value={item.value} key={iId} />
          ))}
        </section>
      ))}
    </Container>
  );
}

export function meta(): Meta {
  const root = globals.location.pathname.match(REGEXP_ROOT)?.[1];
  const articleId = readState.news.latest[root];
  const preview = readState.news.preview[articleId];
  if (!preview)
    return {
      title: ["", ""],
      description: ["", ""],
    };
  const path = [root, articleId].join(".");
  return {
    title: [`news:title.${path}`, preview.title],
    description: [`news:description.${path}`, preview.excerpt],
  };
}

const cssArticle = () => css`
  gap: var(--sp-10);
  section :is(h1, h2, h3, h4, h5, h6):first-child {
    margin: 0 0 var(--sp-1) 0;
  }
  section :is(h1, h2, h3, h4, h5, h6):not(:first-child) {
    margin: var(--sp-10) 0 var(--sp-1) 0;
  }
  section img {
    margin: var(--sp-2) 0;
  }
  section p {
    margin: var(--sp-2) 0;
  }
`;
